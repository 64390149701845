import { useEffect, useState } from 'react'

export enum ImageState {
  None,
  Loading,
  Loaded,
  Failed,
}

type SetImageStateFunc = (state: ImageState) => void

const imageStateCache: Record<
  string,
  {
    state: ImageState
    listeners: SetImageStateFunc[]
  }
> = {}

function registerImageStateListener(
  imageSrc: string,
  setImageState: SetImageStateFunc
) {
  let imageState = imageStateCache[imageSrc]

  if (imageState) {
  } else {
    imageStateCache[imageSrc] = imageState = {
      state: ImageState.None,
      listeners: [],
    }

    const image = new Image()
    image.onload = () => updateState(ImageState.Loaded)
    image.onerror = () => updateState(ImageState.Failed)
    image.src = imageSrc

    function updateState(state: ImageState) {
      imageState.state = state
      imageState.listeners.forEach((callback) => callback(state))
    }
  }

  imageState.listeners.push(setImageState)
  setImageState(imageState.state)
}

function unregisterImageStateListener(
  imageSrc: string,
  setImageState: SetImageStateFunc
) {
  const imageState = imageStateCache[imageSrc]
  if (imageState) {
    const { listeners } = imageState
    const callbackIndex = listeners.indexOf(setImageState)
    if (callbackIndex !== -1) {
      listeners.splice(callbackIndex, 1)
    }
  }
}

export default function useImageState(imageSrc: string) {
  const [imageState, setImageState] = useState(ImageState.None)

  useEffect(() => {
    registerImageStateListener(imageSrc, setImageState)

    return () => {
      unregisterImageStateListener(imageSrc, setImageState)
    }
  }, [imageSrc])

  return imageState
}
