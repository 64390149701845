import classNames from 'classnames'
import React from 'react'

import { AnyTeaser } from '../../../@import-types/contentful/general.types'
import { PageColorDataProps } from '../../../constants/GlobalDOM'
import { useIsDesktopLarge } from '../../../hooks/useIsDesktop'
import { getComplementaryColor } from '../../../utils/colorUtils'
import TeaserCardSwitch from '../../_cards/TeaserCardSwitch'
import AnimatedShade from '../../AnimatedShade'
import LayoutContainer from '../../_layouts/LayoutContainer'
import HeroHeading from '../../_typography/HeroHeading'

import styles from './teasers-hero.module.scss'

interface TeasersHeroProps {
  title: string
  subtitle?: string | null

  teaserA: AnyTeaser
  teaserB: AnyTeaser

  teaserALabel?: string | null

  backgroundColor?: string | null
  textColor?: string | null

  className?: string
}

export default function TeasersHero({
  title,
  subtitle,

  teaserA,
  teaserB,

  teaserALabel,

  backgroundColor,
  textColor,

  className,
}: TeasersHeroProps) {
  const isDesktopLarge = useIsDesktopLarge()

  const teaserALabelColor = isDesktopLarge
    ? backgroundColor
    : getComplementaryColor(backgroundColor)

  return (
    <section className={classNames(styles.container, className)}>
      <AnimatedShade />
      <LayoutContainer className={styles.innerContainer}>
        <div
          className={styles.textsContainer}
          style={{
            color: textColor ?? undefined,
          }}
        >
          <HeroHeading
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />

          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>

        <div className={styles.teasersContainer}>
          <div
            className={styles.backgroundColor}
            style={{
              backgroundColor: backgroundColor ?? undefined,
            }}
            {...PageColorDataProps}
          />

          <div className={styles.teaserAContainer}>
            {teaserALabel && (
              <h2
                className={styles.teaserALabel}
                style={{ color: teaserALabelColor ?? undefined }}
              >
                {teaserALabel}
              </h2>
            )}
            <TeaserCardSwitch className={styles.teaserA} teaser={teaserA} />
          </div>

          <TeaserCardSwitch className={styles.teaserB} teaser={teaserB} />
        </div>
      </LayoutContainer>
    </section>
  )
}
