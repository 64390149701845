import classNames from 'classnames'
import React, { useState } from 'react'

import useImageState, { ImageState } from '../../hooks/useImageState'
import useIsDesktop from '../../hooks/useIsDesktop'
import useOnScroll from '../../hooks/useOnScroll'
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion'
import SvgShadeSunRay from '../_svg/_shades/SvgShadeSunRay'
import SvgWindowGrid from '../_svg/_shades/SvgWindowGrid'

import styles from './animated-shade.module.scss'

interface AnimatedShadeProps {}

export default function AnimatedShade({}: AnimatedShadeProps) {
  const prefersReducedMotion = usePrefersReducedMotion()
  const isDesktop = useIsDesktop()

  const [isFinished, setIsFinished] = useState(false)
  const [shouldFadeOut, setShouldFadeOut] = useState(false)

  const shadeSrc = '/windows.png'
  const shadeLoadState = useImageState(shadeSrc)

  const hasLoadedImage = shadeLoadState === ImageState.Loaded

  useOnScroll(() => {
    if (!hasLoadedImage) {
      return
    }

    setShouldFadeOut(true)
  }, true)

  function onAnimationEnd() {
    setIsFinished(true)
  }

  if (prefersReducedMotion || isFinished || !hasLoadedImage) {
    return null
  }

  return (
    <div
      className={classNames(styles.container, shouldFadeOut && styles.fadeOut)}
      onTransitionEnd={onAnimationEnd}
    >
      <div className={styles.shadeContainer}>
        <img
          className={styles.shade}
          src={shadeSrc}
          onAnimationEnd={onAnimationEnd}
        />
      </div>
    </div>
  )
}
