import { graphql } from 'gatsby'
import React from 'react'

import { AboutPageType } from '../../@import-types/contentful/AboutPage.types'
import type { CaseContent } from '../../@import-types/contentful/Case.types'
import AnimatedShade from '../../components/AnimatedShade'
import ContentArea from '../../components/ContentArea'
import Layout from '../../components/Layout'
import TeasersHero from '../../components/_heroes/TeasersHero'
import SvgShadePalmTree from '../../components/_svg/_shades/SvgShadePalmTree'
// import SvgShadePalmTree from '../../components/_svg/_shades/SvgShadePalmTree'
import Colors from '../../constants/Colors'
import { GlobalStartPageContent } from '../../graphql/global.graphql'
import { RootPageQuery } from '../../graphql/global.graphql'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'

type StartPageProps = RootPageQuery<{
  contentfulStartPage: GlobalStartPageContent<{
    title: string
    subTitle: string

    content: any[]

    highlightedProject: CaseContent

    aboutPage: AboutPageType
  }>
}>

export default function StartPage({ data, pageContext }: StartPageProps) {
  const {
    title,
    subTitle,
    content,
    highlightedProject,
    aboutPage,
  } = data.contentfulStartPage

  const latestProjectsLabel = useMicroCopy(
    'text.latest_project',
    data.commonContent
  )

  return (
    <Layout
      data={data}
      basePageContent={data.contentfulStartPage}
      pageTextColor={Colors.Yellow}
      pageBackgroundColor={Colors.GreenDark}
      pageContext={pageContext}
    >
      {/* <SvgShadePalmTree
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          left: 0,
          zIndex: 10,
          mixBlendMode: 'overlay',
        }}
      /> */}


      <TeasersHero
        title={title}
        subtitle={subTitle}
        teaserALabel={latestProjectsLabel}
        teaserA={highlightedProject}
        teaserB={aboutPage}
        backgroundColor={Colors.GreenDark}
        textColor={Colors.Yellow}
      />

      {content && <ContentArea content={content} />}
    </Layout>
  )
}

export const pageQuery = graphql`
  query StartPageById($startPageId: String!, $locale: String!) {
    contentfulStartPage(id: { eq: $startPageId }) {
      ...BasePageFragment

      title
      subTitle

      content {
        __typename

        ... on Node {
          ...ContentAreaFragment
        }
      }

      aboutPage {
        ...AboutPageTeaserFragment
      }

      highlightedProject {
        ...CaseTeaserFragment
      }
    }

    ...GlobalFragment
  }
`
